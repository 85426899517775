import React from "react";
import RegisteredLocator from "../home/assets/RegisteredLocator.jpg";
import PageHeader from "../common/PageHeader";

export default function LocatorPage() {
  return (
    <section
      id="content"
      className="container clearfix"
      style={{
        padding: "0px",
        outline: "none",
        margin: "0px auto",
        marginBottom: "0px"
      }}
    >
      <PageHeader title={"Locator®"}></PageHeader>
      <div style={{ margin: "0px", padding: "0px", outline: "none" }}>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <img
            src={RegisteredLocator}
            alt="Locator"
            loading="lazy"
            width="221"
            height="52"
          />
          <br />
          <br />
          Smart Locator® is the heart of Attika® System a business solution for
          an open ended spectrum of enterprises. Is an agnostic composition of
          components that can operate as stand alone or part of other services
          to better serve the specific business capability and needs. While its
          expandable and flexible modularity allows to address multi enterprise
          functions as business grows. It is a scalable solution to sustain high
          workloads with predictable performance. It is operating system and
          cloud platform agnostic; while we can interface with all major
          operating systems and cloud providers. Gaining in business
          productivity by quick deployment with zero loss of productivity.
        </p>
      </div>
    </section>
  );
}
